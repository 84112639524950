<template>
    <div class="page" v-if="ready">
        <ProfileMenu section="edit-questionnaire" />
        <div>
            <h1>修改入學問卷</h1>
        </div>
        <div class="form-wrapper" v-if="!isSaved">
            <div class="text">
                <p>由於直資和私立小學的面試均會提出很多家庭背景相關的問題，為了進一步協助學生回答相關的問題，煩請家長填寫以下資料，以便老師能有效地協助學生。</p>
                <p>所有資料只作教學用途，教學完畢後，我們會銷毀相關資料。</p>
            </div>
            <div class="page-group">
                <div class="legend">
                    <ul class="group">
                        <li><label>學生中文全名 (*)</label></li>
                        <li>
                            <input type="text" v-model="form.student_chinese_name" required placeholder="您的答案" ref="student_chinese_name" />
                            <div v-if="error === 'student_chinese_name'" class="error">請填寫學生中文全名</div>
                        </li>
                    </ul>
                    <ul class="group">
                        <li><label>學生英文全名 (*)</label></li>
                        <li>
                            <input type="text" v-model="form.student_english_name" required placeholder="您的答案" ref="student_english_name" />
                            <div v-if="error === 'student_english_name'" class="error">請填寫學生英文全名</div>
                        </li>
                    </ul>
                    <ul class="group">
                        <li><label>學生出生日期 (*)</label></li>
                        <li>
                            <div class="date-picker">
                                <datePicker v-model="form.student_dob" :locale="locale" :upperLimit="upperLimit" :lowerLimit="lowerLimit" startingView="year" :weekStartsOn="0" />
                                <div v-if="error === 'student_dob'" class="error">請選擇學生出生日期</div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="legend">
                    <ul class="group">
                        <li><label>住址地區 (*)</label></li>
                        <li>
                            <app-select :value="form.district_id" :data="district" placeholder="選擇地區" display_field="tc_name" @update="updateDistrict" />
                            <div v-if="error === 'district_id'" class="error">請選擇住址地區</div>
                        </li>
                    </ul>
                    <ul class="group">
                        <li><label>中文住址 (*)</label></li>
                        <li>
                            <input type="text" v-model="form.chinese_address" required placeholder="您的答案" ref="chinese_address" />
                            <div v-if="error === 'chinese_address'" class="error">請填寫中文住址</div>
                        </li>
                    </ul>
                    <ul class="group">
                        <li><label>英文住址 (*)</label></li>
                        <li>
                            <input type="text" v-model="form.english_address" required placeholder="您的答案" ref="english_address" />
                            <div v-if="error === 'english_address'" class="error">請填寫英文住址</div>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="page-group">
                <h2>學校資料</h2>
                <div class="legend">
                    <ul class="group">
                        <li><label>學校中文名稱 (*)</label></li>
                        <li>
                            <input type="text" v-model="form.school_chinese_name" required placeholder="您的答案" ref="school_chinese_name" />
                            <div v-if="error === 'school_chinese_name'" class="error">請填寫學校中文名稱</div>
                        </li>
                    </ul>
                    <ul class="group">
                        <li><label>學校英文名稱 (*)</label></li>
                        <li>
                            <input type="text" v-model="form.school_english_name" required placeholder="您的答案" ref="school_english_name" />
                            <div v-if="error === 'school_english_name'" class="error">請填寫學校英文名稱</div>
                        </li>
                    </ul>
                    <ul class="group">
                        <li><label>上午/下午班/全日 (*)</label></li>
                        <li>
                            <div class="group-radio">
                                <div><input type="radio" v-model="form.school_type" value="上午班"><label>上午班</label></div>
                                <div><input type="radio" v-model="form.school_type" value="下午班"><label>下午班</label></div>
                                <div><input type="radio" v-model="form.school_type" value="全日"><label>全日</label></div>
                            </div>
                            <div v-if="error === 'school_type'" class="error">請選擇上午/下午班/全日</div>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="page-group">
                <h2>其他資料</h2>
                <div class="legend">
                    <ul class="group">
                        <li><label>同住的家庭成員 (*)</label></li>
                        <li>
                            <input type="text" v-model="form.family_member" required placeholder="您的答案" ref="family_member" />
                            <div class="remark">(如爸爸、媽媽、哥哥、工人姐姐及學生自己，共5人)</div>
                            <div v-if="error === 'family_member'" class="error">請填寫同住的家庭成員</div>
                        </li>
                    </ul>
                    <ul class="group">
                        <li><label>兄弟姐妹年齡及就讀學校</label></li>
                        <li>
                            <input type="text" v-model="form.sibling_school" required placeholder="您的答案" />
                            <div class="remark">(如 哥哥:12歲 - XX中學 ，姐姐:8歲- XXX小學)</div>
                        </li>
                    </ul>
                    <ul class="group">
                        <li><label>父親職業 (*)</label></li>
                        <li>
                            <input type="text" v-model="form.father_work" required placeholder="您的答案" ref="father_work" />
                            <div v-if="error === 'father_work'" class="error">請填寫父親職業</div>
                        </li>
                    </ul>
                    <ul class="group">
                        <li><label>父親聯絡電話 (*)</label></li>
                        <li>
                            <input type="text" v-model="form.father_phone" required placeholder="您的答案" ref="father_phone" />
                            <div v-if="error === 'father_phone'" class="error">請填寫父親聯絡電話</div>
                        </li>
                    </ul>
                    <ul class="group">
                        <li><label>母親職業 (*)</label></li>
                        <li>
                            <input type="text" v-model="form.mother_work" required placeholder="您的答案" ref="mother_work" />
                            <div v-if="error === 'mother_work'" class="error">請填寫母親職業</div>
                        </li>
                    </ul>
                    <ul class="group">
                        <li><label>母親聯絡電話 (*)</label></li>
                        <li>
                            <input type="text" v-model="form.mother_phone" required placeholder="您的答案" ref="mother_phone" />
                            <div v-if="error === 'mother_phone'" class="error">請填寫母親聯絡電話</div>
                        </li>
                    </ul>
                    <ul class="group">
                        <li><label>現時參與課外活動 (*)</label></li>
                        <li>
                            <input type="text" v-model="form.activity" required placeholder="您的答案" ref="activity" />
                            <div class="remark">（如：普通話朗誦、Cambridge、繪畫、網球⋯）</div>
                            <div v-if="error === 'activity'" class="error">請填寫現時參與課外活動</div>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="page-group last">
                <div class="text no-padding">
                    <p>欲報讀之直資和私立小學<br>（請列出3-5間小學名稱）</p>
                </div>
                <div class="legend">
                    <ul class="group">
                        <li><label>小學(一) (*)</label></li>
                        <li>
                            <input type="text" v-model="form.school_1" required placeholder="您的答案" ref="school_1" />
                            <div v-if="error === 'school_1'" class="error">請填寫小學(一)</div>
                        </li>
                    </ul>
                    <ul class="group">
                        <li><label>小學(二) (*)</label></li>
                        <li>
                            <input type="text" v-model="form.school_2" required placeholder="您的答案" ref="school_2" />
                            <div v-if="error === 'school_2'" class="error">請填寫小學(二)</div>
                        </li>
                    </ul>
                    <ul class="group">
                        <li><label>小學(三) (*)</label></li>
                        <li>
                            <input type="text" v-model="form.school_3" required placeholder="您的答案" ref="school_3" />
                            <div v-if="error === 'school_3'" class="error">請填寫小學(三)</div>
                        </li>
                    </ul>
                    <ul class="group">
                        <li><label>小學(四)</label></li>
                        <li><input type="text" v-model="form.school_4" required placeholder="您的答案" /></li>
                    </ul>
                    <ul class="group">
                        <li><label>小學(五)</label></li>
                        <li><input type="text" v-model="form.school_5" required placeholder="您的答案" /></li>
                    </ul>
                    <div class="br-line"></div>
                    <ul class="group">
                        <li><label>父母是否以上小學的畢業生</label></li>
                        <li>
                            <div class="group-radio">
                                <div><input type="radio" v-model="form.parent_is_graduate" value="是"><label>是</label></div>
                                <div><input type="radio" v-model="form.parent_is_graduate" value="否"><label>否</label></div>
                            </div>
                            <div v-if="error === 'parent_is_graduate'" class="error">請選擇父母是否以上小學的畢業生</div>
                        </li>
                    </ul>
                    <ul class="group">
                        <li><label>如是，請填寫該校名稱</label></li>
                        <li>
                            <input type="text" v-model="form.parent_is_graduate_yes_name" required placeholder="您的答案" ref="parent_is_graduate_yes_name" />
                            <div class="remark">(例如 : 爸爸 - XXX小學)</div>
                            <div v-if="error === 'parent_is_graduate_yes_name'" class="error">請填寫該校名稱</div>
                        </li>
                    </ul>
                    <div class="br-line"></div>
                    <ul class="group">
                        <li><label>哥哥/姐姐是否於以上學校就讀 / 畢業？</label></li>
                        <li>
                            <div class="group-radio">
                                <div><input type="radio" v-model="form.sibling_is_graduate" value="是"><label>是</label></div>
                                <div><input type="radio" v-model="form.sibling_is_graduate" value="否"><label>否</label></div>
                            </div>
                            <div v-if="error === 'sibling_is_graduate'" class="error">請選擇哥哥/姐姐是否於以上學校就讀 / 畢業</div>
                        </li>
                    </ul>
                    <ul class="group">
                        <li><label>如是，請填寫該校名稱</label></li>
                        <li>
                            <input type="text" v-model="form.sibling_is_graduate_yes_name" required placeholder="您的答案" ref="sibling_is_graduate_yes_name" />
                            <div class="remark">(例如 : 哥哥 - XX小學，姐姐 - XXX小學)</div>
                            <div v-if="error === 'sibling_is_graduate_yes_name'" class="error">請填寫該校名稱</div>
                        </li>
                    </ul>
                </div>
            </div>
            
            <div class="control" v-if="!submitting && !submit_error">
                <input type="submit" value="儲存" @click="submit" />
            </div>
            <div class="loader" v-if="submitting && !submit_error">
                <span class="icon-loading"></span><span>資料儲存中...</span>
            </div>
        </div>

        <div class="successfully-page" v-if="isSaved">
            <div class="message">
                您的入學問卷已成功儲存
            </div>
            <div class="control">
                <input type="button" value="返回" @click="back" />
            </div>
        </div>
    </div>
</template>

<script src="./EditQuestionnaire.js"></script>

<style scoped lang="scss">
@import 'Page.scss';
.page-group {
    padding-bottom:20px;
    border-bottom:1px solid #ccc;
    max-width:890px;
    width:90vw;
    margin:0px auto 40px auto;
    &.last { border-bottom:0px; padding-bottom:0px; }
}
.text {
    text-align:center; padding-bottom:30px; max-width:70%; margin:auto;
    margin-top:30px;
    &.no-padding { padding:0px; }
}
</style>
 