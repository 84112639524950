import ProfileMenu from './ProfileMenu'
import zh from 'date-fns/locale/zh-TW'
import datePicker from 'vue3-datepicker'
import select from '@/components/elements/select'
import Fn from './Fn'
export default {
    mixins:[ Fn ],
    components: { 
        datePicker, 
        'app-select': select,
        ProfileMenu
    },
    data() {
        return {
            ready: false,
            isSaved: false,
            locale: zh,
            upperLimit: null,
            lowerLimit: null,
            error: '',
            data: null,
            submit_error: false,
            submitting: false,
            form: {},
            district: [],
            requiredField: [
                'student_chinese_name', 'student_english_name', 'student_dob',
                'district_id', 'chinese_addresss', 'english_address',
                'school_chinese_name', 'school_english_name', 'school_type',
                'family_member', 'father_work', 'father_phone', 'mother_work', 'mother_phone',
                'activity',
                'school_1', 'school_2', 'school_3'

            ],
        }
    },
    mounted() {
        this.$root.updateNotification()
        
        this.upperLimit = this.getLimitDate('today')
        this.lowerLimit = this.getLimitDate({ year:-20 })

        const params = new FormData()
        params.append('token', this.Auth.getToken())

        this.$axios
            .post(process.env.VUE_APP_API + 'edit-questionnaire' + process.env.VUE_APP_SERVER_SIDE_LANG, params)
            .then(response => {
                let r = response.data
                if (r.success === true) {

                    for (let i in r.data) {
                        switch (i) {
                            case 'student_dob' : {
                                this.form[i] = new Date(r.data[i])
                                break
                            }
                            default : {
                                this.form[i] = r.data[i]
                                break
                            }
                        }
                    }

                    this.$axios
                        .post(process.env.VUE_APP_API + 'get-district' + process.env.VUE_APP_SERVER_SIDE_LANG, params)
                        .then(response => {
                            let r = response.data
                            if (r.success === true) {
                                this.district = r.data
                                this.ready = true
                            }
                        })
                }
            })
    },
    methods: {
        updateDistrict(value) {
            this.form.district_id = value
        },
        back() {
            this.isSaved = false
        },
        submit() {
            this.error = false
        
            for (let i in this.requiredField) {
                if (this.form[this.requiredField[i]] == '') {
                    this.error = this.requiredField[i]
                    this.$refs[this.requiredField[i]].focus()
                    break
                }
            }

            if (this.error == false) {
                this.submitting = true

                const params = new FormData()
                for (let i in this.form) {
                    switch (i) {
                        case 'student_dob' : {
                            let date = new Date(this.form[i])
                            params.append(i, date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate())
                            break
                        }
                        default : {
                            params.append(i, this.form[i])
                            break
                        }
                    }
                }
                params.append('token', this.Auth.getToken())
                
                this.$axios
                    .post(process.env.VUE_APP_API + 'edit-questionnaire-db' + process.env.VUE_APP_SERVER_SIDE_LANG, params)
                    .then(response => {
                        let r = response.data
                        console.log(r)
                        if (r.success === true) {
                            this.isSaved = true
                        }

                        this.submitting = false
                    })
            }
        }
    }
}